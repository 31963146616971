import { Box, Button, Card, Grid, Stack } from "@mui/material";
import Image from "next/legacy/image";
import Link from "next/link";
import React from "react";
import styles from "./FeaturedContentCard.module.css";

interface FeaturedContentCardProps {
	title: string;
	image: string;
	text?: string;
	buttonLabel?: string;
	buttonIcon?: React.ReactNode;
	pictureWide?;
	wide?: boolean;
	buttonHref?: string;
}

const PICTURE_HEIGHT = "220px";
const PICTURE_WIDE_WIDTH = "393px";
const PICTURE_NARROW_WIDTH = "202px";

function FeaturedContentCard({
	title,
	image,
	text,
	buttonLabel,
	buttonIcon,
	pictureWide,
	buttonHref,
}: FeaturedContentCardProps) {
	const pictureWidth = pictureWide ? PICTURE_WIDE_WIDTH : PICTURE_NARROW_WIDTH;
	return (
		<Card variant="outlined">
			<Grid container direction="row">
				<Grid item xs={12} sm={5} md={4} lg={3}>
					<Box
						sx={{
							position: "relative",
							minWidth: pictureWidth,
							minHeight: PICTURE_HEIGHT,
							height: "100%",
						}}
					>
						<Image
							src={image}
							layout="fill"
							className={styles["card-image"]}
							alt={title}
						/>
					</Box>
				</Grid>
				<Grid
					item
					direction="column"
					spacing={{ sx: 6, sm: 6, md: 4, lg: 4 }}
					p={4}
					xs={12}
					sm={7}
					md={8}
					lg={9}
				>
					<Stack direction="column" spacing={1} pb={4}>
						<h4 className="mt-5 pb-1">{title}</h4>
						<p className="body1 text-secondary">{text}</p>
					</Stack>

					{buttonLabel && (
						<Link href={buttonHref} passHref>
							<Button
								color="secondary"
								variant="contained"
								size="large"
								startIcon={buttonIcon}
							>
								{buttonLabel}
							</Button>
						</Link>
					)}
				</Grid>
			</Grid>
		</Card>
	);
}

export default FeaturedContentCard;
