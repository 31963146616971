import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Box, Button, Divider, Grid2 as Grid, Stack } from "@mui/material";
import FormSelect, {
	FormSelectProps,
} from "common/components/FormSelect/FormSelect";

interface CustomPadding {
	pt: number;
	pb: number;
}
interface CustomGrid {
	sm: number[];
	md: number[];
	lg: number[];
}
interface IPageHeaderProps {
	pageTitle: string;
	pageSubtitle?: string;
	pageTitleExtra?: string | number;
	pageDivider?: boolean;
	pageGreeting?: string;
	showGoBackButton?: boolean;
	customPadding?: CustomPadding;
	customGreetingGrid?: CustomGrid;
	pageFilter?: FormSelectProps;
	previousStep?: () => void;
}

function PageHeader({
	pageTitle,
	pageSubtitle,
	pageGreeting,
	pageTitleExtra,
	pageDivider,
	showGoBackButton,
	previousStep,
	customPadding,
	customGreetingGrid,
	pageFilter,
}: IPageHeaderProps) {
	return (
		<Stack spacing={2} sx={customPadding || { pt: 5, pb: 5 }}>
			{showGoBackButton && (
				<Box mb={3.5}>
					<Button
						variant="outlined"
						color="secondary"
						startIcon={<ArrowBackIcon />}
						onClick={previousStep}
					>
						Go Back
					</Button>
				</Box>
			)}
			<title>{pageTitle}</title>
			<h4>
				{pageTitle}
				{pageTitleExtra && (
					<h4
						className="text-secondary fw-4"
						style={{ paddingLeft: "8px", paddingRight: "8px" }}
					>
						{pageTitleExtra}
					</h4>
				)}
				{pageFilter && <FormSelect {...pageFilter} />}
			</h4>
			{pageDivider && <Divider orientation="horizontal" />}
			{pageSubtitle && <h6 className="text-secondary">{pageSubtitle}</h6>}
			{customGreetingGrid ? (
				<Grid container spacing={0}>
					<Grid
						size={{
							xs: customGreetingGrid.sm[0],
							md: customGreetingGrid.md[0],
							lg: customGreetingGrid.lg[0],
						}}
					>
						<p className="body1 text-secondary">{pageGreeting}</p>
					</Grid>
					<Grid
						size={{
							xs: customGreetingGrid.sm[1],
							md: customGreetingGrid.md[1],
							lg: customGreetingGrid.lg[1],
						}}
					></Grid>
				</Grid>
			) : (
				<Grid container spacing={0}>
					<Grid
						size={{
							xs: 12,
							md: 9,
						}}
					>
						<p className="body1 text-secondary">{pageGreeting}</p>
					</Grid>
					<Grid
						size={{
							xs: 0,
							md: 3,
							lg: 3,
						}}
					></Grid>
				</Grid>
			)}
		</Stack>
	);
}

export default PageHeader;
