import { SvgIconComponent } from "@mui/icons-material";
import { Avatar, Card, Stack } from "@mui/material";
import Link from "next/link";

interface INavCardContentProps {
	label: string;
	content: string;
	icon: SvgIconComponent | any;
	disabled?: boolean;
}
interface INavCardProps extends INavCardContentProps {
	href?: string;
}

const CardContent = ({
	disabled,
	content,
	label,
	icon,
}: INavCardContentProps) => (
	<Stack spacing={2}>
		<Avatar
			variant="square"
			sx={{
				color: disabled ? "grey.400" : "black",
			}}
		>
			{icon}
		</Avatar>
		<Stack spacing={1}>
			<h6
				style={{
					color: disabled ? "secondary.light" : "black",
				}}
			>
				{label}
			</h6>
			<p className="body1">{content}</p>
		</Stack>
	</Stack>
);

function NavCard({ href, ...contentProps }: INavCardProps) {
	return (
		<Card
			variant="outlined"
			sx={{
				borderColor: contentProps.disabled ? "grey.500" : "grey.A700",
				height: 226,
				p: 5,
			}}
		>
			{href ? (
				<Link color="text.primary" href={href} passHref>
					<CardContent {...contentProps} />
				</Link>
			) : (
				<CardContent {...contentProps} />
			)}
		</Card>
	);
}

export default NavCard;
